import React, { useEffect } from 'react';
import {
  Box,
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Alert,
} from '@mui/material';
import { useGetAdminsQuery } from '../../app/services/authApi';

const ListSupAdmins = () => {
  const { data, isLoading, isError, error, refetch } = useGetAdminsQuery('supadmin');

  // Rafraîchit les données à chaque fois que le composant est monté
  useEffect(() => {
    refetch(); // Appel de refetch uniquement lorsque le composant est monté
  }, [refetch]);
useEffect(() => {
    // Remettre le défilement en haut de la page à chaque chargement
    window.scrollTo(0, 0);
}, []);
  return (
    <Container
      maxWidth="xl"
      sx={{
        backgroundColor: "#ffff8d",
        pt: 0,
        pb: 2,
        marginTop: 0,
      }}
    >
      <Typography variant="h5">Liste des super administrateurs</Typography>
      <Typography paragraph>
        Les super administrateurs ont un contrôle total sur l'ensemble des fonctionnalités.
      </Typography>

      <Box sx={{ backgroundColor: "white", padding: 2, borderRadius: 4 }}>
        {isLoading ? (
          <CircularProgress />
        ) : isError ? (
          <Alert severity="error">
            Une erreur est survenue lors du chargement des administrateurs : {error.message}
          </Alert>
        ) : (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="admin list table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: 'bold' }}>ID</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>Nom</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>Email</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>Rôle</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>Date d'inscription</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.map((admin) => (
                  <TableRow key={admin.id}>
                    <TableCell>{admin.id}</TableCell>
                    <TableCell>{admin.name}</TableCell>
                    <TableCell>{admin.email}</TableCell>
                    <TableCell>{admin.role}</TableCell>
                    <TableCell>{new Date(admin.created_at).toLocaleDateString()}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>
    </Container>
  );
};

export default ListSupAdmins;
