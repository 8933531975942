import {createApi,fetchBaseQuery} from '@reduxjs/toolkit/query/react'

export const reservationApi=createApi({
    reducerPath:'reservationApi',
    baseQuery:fetchBaseQuery({
        // baseUrl:'http://localhost:8000/api/reservations'
        baseUrl: process.env.REACT_APP_BASE_URL,
        credentials: 'include',
    }),
    tagTypes:["Reservations"],
    keepUnusedDataFor:60,
    endpoints:(builder)=>({

        getAllReservations:builder.query({
            query:(etat)=>({url:`reservations/etat/${etat}`,

            }),
            providesTags:['Reservations']
        }),

        getReservation:builder.query({
            query:(id)=>({url:`reservations/${id}`,
            }),
            providesTags:['Reservations']
        }),
        getReservationsProfile:builder.query({
            query:(id)=>({url:`reservations/profile/${id}`,
            }),
            providesTags:['Reservations']
        }),
        addReservation:builder.mutation({
            query:(body)=>({
                url:'reservations/',
                method:'POST',
                body
            }),
            invalidatesTags:['Reservations','Notifications']
        }),


        // updateReservation:builder.mutation({
        //     query:(id)=>({
        //         url:`reservations/update/${id}`,
        //         // method:'GET',
        //     }),
        //      invalidatesTags:['Reservations']
        // })  
        updateReservation:builder.mutation({
            query:(body)=>({
                url:'reservations/update',
                method:'POST',
                body
            }),
            invalidatesTags:['Reservations']
        }), 

    })
})
export const {useGetAllReservationsQuery,useGetReservationQuery,useGetReservationsProfileQuery,useAddReservationMutation,useUpdateReservationMutation}=reservationApi;
