import React from 'react';
import { useGetReservationsProfileQuery } from '../../app/services/reservationApi';
import Loader from '../loader/Loader';
import dayjs from "dayjs";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  MenuItem,
  Stack,
  Typography,
  Box,
  InputLabel,
  Select,
} from '@mui/material';


function ReservationsProfile(props) {
  const [etat, setEtat] = React.useState("");
  const handleChange = (event) => {
    setEtat(event.target.value);
  };
  const {
    data: reservations,
    isLoading,
    isError,
  } = useGetReservationsProfileQuery(props.id);
  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return <p>Une erreur est survenue lors du chargement des réservations.</p>;
  }


  const filteredReservations = reservations.filter((reservation) => {
    if (etat === 0) return true; // Afficher toutes les réservations
    const etatMap = {
      1: "En cours",
      2: "Validée",
      3: "Terminée"
    };
    return reservation.etat === etatMap[etat]; // Comparer avec la valeur correspondante
  });

  return (
    <div>
  

      <Typography variant="h5">
      Mes réservations
        </Typography>
      

      <Stack
        direction={{ xs: "column", sm: "row" }}
        spacing={4}
        align="left"
        sx={{
          backgroundColor: "white",
          border: "solid",
          marginBottom: 2,
          borderRadius: 2,
          padding: 2,
          display: "flex",
        }}
      >
        <Stack direction={"column"} align="left">
        <Stack
        direction={"row"}
        align="left"
        spacing={2}
        sx={{ pt: 1, pb: 1, pl: 2 }}
      >
        <Box sx={{ width: 64, backgroundColor: "#ffeb3b" }}></Box>
        <Typography variant="body2">
          Couleur des réservations validées
        </Typography>
      </Stack>

      <Stack
        direction={"row"}
        align="left"
        spacing={2}
        sx={{ pt: 1, pb: 1, pl: 2 }}
      >
        <Box sx={{ width: 64, backgroundColor: "#ff9800" }}></Box>
        <Typography variant="body2">
          Couleur des réservations terminées
        </Typography>
      </Stack>
        </Stack>


        <Stack direction={"column"} spacing={2} align="left">
          <InputLabel id="demo-simple-select-label">
          <Typography
  variant="h6" // Variante pour une taille par défaut
  sx={{
    fontSize: { xs: "0.7rem", sm: "1rem", md: "1rem" }, // Taille responsive
    fontWeight: "bold",
  }}
>
  Filtre selon l'état de réservation
</Typography>
          </InputLabel>
          <Select
            sx={{ width: { xs: 184, sm: 240 } }}
            size="small"
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={etat}
            label="Etat"
            onChange={handleChange}
          >
            <MenuItem value={0}>Tout</MenuItem>
            <MenuItem value={1}>En cours</MenuItem>
            <MenuItem value={2}>Validée</MenuItem>
            <MenuItem value={3}>Terminée</MenuItem>
          </Select>
        </Stack>
      </Stack>



      <TableContainer component={Paper} sx={{ minHeight: 400 }}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow sx={{ backgroundColor: "black" }}>
              <TableCell
                align="left"
                sx={{ fontWeight: "bold", color: "yellow" }}
              >
                Code
              </TableCell>
              {/* <TableCell
                align="left"
                sx={{ fontWeight: "bold", color: "yellow" }}
              >
                Nom
              </TableCell> */}
              {/* <TableCell align="left" sx={{ fontWeight: 'bold',color:'yellow'}}>Prénom</TableCell> */}
              <TableCell
                align="left"
                sx={{ fontWeight: "bold", color: "yellow" }}
              >
                Transaction
              </TableCell>
              <TableCell
                align="left"
                sx={{ fontWeight: "bold", color: "yellow" }}
              >
                Etat
              </TableCell>
              <TableCell
                align="left"
                sx={{ fontWeight: "bold", color: "yellow" }}
              >
                Montant
              </TableCell>
              <TableCell
                align="left"
                sx={{ fontWeight: "bold", color: "yellow" }}
              >
                Devise
              </TableCell>
              <TableCell
                align="left"
                sx={{ fontWeight: "bold", color: "yellow" }}
              >
                MAD
              </TableCell>
              <TableCell
                align="left"
                sx={{ fontWeight: "bold", color: "yellow" }}
              >
                Date reservation
              </TableCell>
              <TableCell
                align="left"
                sx={{ fontWeight: "bold", color: "yellow" }}
              >
                Date retrait
              </TableCell>

              <TableCell
                align="left"
                sx={{ fontWeight: "bold", color: "yellow" }}
              >
                Heure retrait
              </TableCell>
              <TableCell
                align="left"
                sx={{ fontWeight: "bold", color: "yellow" }}
              >
                Bureau retrait
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredReservations.map((reservation) => (
                <TableRow
                  key={reservation.id}
                  //  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}

                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    marginLeft: 4,
                    marginRight: 4,
                    backgroundColor:
                      reservation.etat === "Validée"
                        ? "#ffeb3b":
                          reservation.etat === "Terminée"
                            ? "#ff9800"
                            : "white",
                  }}
                >
                  <TableCell align="left">{reservation.id}</TableCell>
                  {/* <TableCell align="left">
                    {reservation.nomFamille} {reservation.prenom}
                  </TableCell> */}
                  {/* <TableCell align="left">{reservation.nomFamille2}</TableCell> */}
                  {/* <TableCell align="left">{reservation.prenom}</TableCell> */}

                  <TableCell align="left">
                    {reservation.typeOperation==="vente"?"Achat":"Vente"}
                  </TableCell>
                  <TableCell align="left">{reservation.etat}</TableCell>
                  <TableCell align="left">
                    {reservation.typeOperation === "achat"
                      ? reservation.montantToExchange
                      : reservation.MontantToReceive}
                  </TableCell>
                  <TableCell align="left">{reservation.devise}</TableCell>
                  <TableCell align="left">
                    {reservation.typeOperation === "achat"
                      ? reservation.MontantToReceive
                      : reservation.montantToExchange}
                  </TableCell>
                  <TableCell align="left">
                    {dayjs(reservation.created_at).format("DD/MM/YYYY")}
                  </TableCell>
                  <TableCell align="left">
                    {dayjs(reservation.dateRetrait).format("DD/MM/YYYY")}
                  </TableCell>
                  <TableCell align="left">
                    {reservation.heureRetrait}
                  </TableCell>
                  <TableCell align="left">
                    {reservation.bureau.nom}
                  </TableCell>
                  {/* <TableCell align="left">
                    <Button
                      sx={{ fontWeight: "bold", color: "#212121" }}
                      size="small"
                      onClick={() => {
                        navigate(`/admin/detailsreservation/${reservation.id}`);
                      }}
                    >
                      <ManageAccountsIcon />
                    </Button>
                  </TableCell> */}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default ReservationsProfile;
