import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({
    // baseUrl:'http://localhost:8000/api',
    baseUrl: process.env.REACT_APP_BASE_URL,
    credentials: "include",
  }),
  tagTypes: ["Auth"],
  keepUnusedDataFor: 60,
  endpoints: (builder) => ({
    getAdmins: builder.query({
      query: (type) => ({ url: `/users/${type}` }),
      invalidatesTags: ["Auth"],
    }),
    getUser: builder.query({
      query: (id) => ({ url: `/user/${id}` }),
      invalidatesTags: ["Auth"],
    }),
    loginUser: builder.mutation({
      query: (body) => ({
        url: "/login",
        method: "POST",
        body,
      }),
      // invalidatesTags:['Devises']
    }),
    registerUser: builder.mutation({
      query: (body) => ({
        url: `/register`,
        method: "POST",
        body,
      }),
      // invalidatesTags:['Auth']
    }),
    // Modifier role
    updateRole: builder.mutation({
      query: ({ id, roleId,action }) => ({
        url: `/role/${id}`,
        method: "PUT",
        body: { id, roleId,action },
      }),
      invalidatesTags: ["Auth"],
    }),
  }),
});
export const {
  useGetUserQuery,
  useGetAdminsQuery,
  useLoginUserMutation,
  useRegisterUserMutation,
  useUpdateRoleMutation,
} = authApi;
