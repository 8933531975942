import React, { useState } from "react";
import { useAuth } from "../../../contexts/AuthContext";
import {
  Box,
  Tabs,
  Tab,
  Typography,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  useMediaQuery,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Login from "./Login";
import ChangePassword from "./ChangePassword";
import ReservationsProfile from "../../reservation/ReservationsProfile";

export default function Profile() {
  const { user } = useAuth();
  const [tabIndex, setTabIndex] = useState(0);
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm")); // Vérifie si l'écran est petit

  // Gestion du changement de tab
  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "100%",
        backgroundColor: "white",
        p: 2,
        boxSizing: "border-box",
      }}
    >
      {user ? (
        <Paper
          sx={{
            width: "100%",
            boxShadow: 3,
            borderRadius: 2,
            p: 2,
            display: "flex",
            flexDirection: "column",
            gap: 3,
          }}
        >
          <Typography
            variant="h3"
            textAlign="center"
            color="primary"
            sx={{
              fontSize: {
                xs: "1.5rem", // Petite taille d'écran (smaller than 600px)
                sm: "2.5rem", // Écran de taille moyenne
                md: "3rem", // Taille d'écran standard (moyenne)
              },
              fontWeight: "bold",
            }}
          >
            EXCHANGE.COM
          </Typography>

          <hr className="bg-slate-400 h-1 w-full my-2" />

          {/* Utilisation de Tabs ou Accordion en fonction de la taille de l'écran */}
          {!isSmallScreen ? (
            <>
              {/* Tabs pour les écrans moyens et grands */}
              <Tabs
                value={tabIndex}
                onChange={handleTabChange}
                aria-label="Profile Tabs"
                sx={{ borderBottom: 1, borderColor: "divider", mb: 2 }}
                centered
              >
                <Tab label="Profil" />
                <Tab label="Changer le mot de passe" />
                <Tab label="Mes réservations" />
              </Tabs>

              {/* Contenu des Tabs */}
              {tabIndex === 0 && (
                <Box sx={{ mt: 2, height: 400 }}>
                  <Typography variant="h5" className="font-bold">
                    Code: {user?.user.id}
                  </Typography>
                  <Typography variant="h5" className="font-bold">
                    Name: {user?.user.name}
                  </Typography>
                  <Typography>Email: {user?.user.email}</Typography>
                  <Typography>
                    Created At:{" "}
                    {new Date(user?.user.created_at).toLocaleDateString()}
                  </Typography>
                </Box>
              )}

              {tabIndex === 1 && (
                <Box sx={{ mt: 2 }}>
                  <ChangePassword />
                </Box>
              )}

              {tabIndex === 2 && (
                <Box sx={{ mt: 2 }}>
                  <ReservationsProfile id={user?.user.id} />
                </Box>
              )}
            </>
          ) : (
            <>
              {/* Accordion pour les petits écrans */}
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="profile-content"
                  id="profile-header"
                >
                  <Typography>Profil</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box sx={{ mt: 2 }}>
                    <Typography variant="h5" className="font-bold">
                      Code: {user?.user.id}
                    </Typography>
                    <Typography variant="h5" className="font-bold">
                      Name: {user?.user.name}
                    </Typography>
                    <Typography>Email: {user?.user.email}</Typography>
                    <Typography>
                      Created At:{" "}
                      {new Date(user?.user.created_at).toLocaleDateString()}
                    </Typography>
                  </Box>
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="change-password-content"
                  id="change-password-header"
                >
                  <Typography>Changer le mot de passe</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box sx={{ mt: 2 }}>
                    <ChangePassword />
                  </Box>
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="reservations-content"
                  id="reservations-header"
                >
                  <Typography>Mes réservations</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box sx={{ mt: 2 }}>
                    <ReservationsProfile id={user?.user.id} />
                  </Box>
                </AccordionDetails>
              </Accordion>
            </>
          )}
        </Paper>
      ) : (
        <Login />
      )}
    </Box>
  );
}
