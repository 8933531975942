import React, { useState, useEffect } from 'react';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Container,
  Grid,
  InputLabel,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import SearchSharpIcon from '@mui/icons-material/SearchSharp';
import { useGetUserQuery, useUpdateRoleMutation } from '../../app/services/authApi';

const RoleAdmin = () => {
  const [code, setCode] = useState('');
  const [userId, setUserId] = useState('');
  const [userNotFound, setUserNotFound] = useState(false);

  const { data: userData, isLoading, isError, error, refetch } = useGetUserQuery(userId, { skip: !userId });
  const [updateRole] = useUpdateRoleMutation();

  const handleSearchUser = () => {
    setUserNotFound(false);
    if (!code) return;
    setUserId(code);
  };

  useEffect(() => {
    if (isError && error?.status === 404) {
      setUserNotFound(true);
    }
  }, [isError, error]);

  const handleRoleToggle = (role) => {
    const hasRole = userData?.user?.roles?.some((r) => r.id === role.id);
    const action = hasRole ? 'remove' : 'assign';

    updateRole({ id: userId, roleId: role.id, action }).then(() => {
      refetch();
    });
  };
useEffect(() => {
    // Remettre le défilement en haut de la page à chaque chargement
    window.scrollTo(0, 0);
}, []);
  return (
    <Container
      maxWidth="xl"
      sx={{
        backgroundColor: '#ffff8d',
        pt: 0,
        pb: 2,
        marginTop: 0,
      }}
    >
      <Typography variant="h5">Gestion des autorisations</Typography>
      <Typography paragraph>
        Dans cette section, vous pouvez rechercher un utilisateur afin de lui attribuer ou retirer des rôles.
      </Typography>

      {/* Recherche utilisateur */}
      <Stack
        direction="row"
        spacing={4}
        align="left"
        justifyContent="center"
        alignItems="end"
        sx={{
          backgroundColor: 'white',
          border: 'solid',
          marginBottom: 2,
          borderRadius: 2,
          padding: 2,
          display: 'flex',
        }}
      >
        <Stack direction="column" align="left">
          <Typography variant="h6">Recherche</Typography>
          <Typography variant="body2">
            Saisissez le code utilisateur dans la zone de recherche, puis cliquez sur le bouton <strong>Recherche</strong>.
          </Typography>
        </Stack>
        <Stack direction="column" spacing={2} align="left">
          <InputLabel id="code">Code utilisateur</InputLabel>
          <TextField
            id="code"
            name="code"
            size="small"
            value={code}
            onFocus={(event) => event.target.select()}
            onChange={(event) => setCode(event.target.value)}
          />
        </Stack>
        <Stack sx={{ height: '100%' }}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={handleSearchUser}
          >
            <SearchSharpIcon />Recherche
          </Button>
        </Stack>
      </Stack>

      {userNotFound && (
        <Alert severity="error" sx={{ marginTop: 2 }}>
          Utilisateur non trouvé
        </Alert>
      )}

      {/* Informations utilisateur et gestion des rôles */}
      <Box sx={{ backgroundColor: 'white', padding: 2, borderRadius: 4 }}>
        <Typography variant="h5" color="black">
          Informations du client
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <InputLabel id="code-label">Code</InputLabel>
            <TextField
              fullWidth
              id="code"
              name="code"
              size="small"
              value={userData?.user?.id || ''}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <InputLabel id="nom-label">Nom</InputLabel>
            <TextField
              fullWidth
              id="nom"
              name="nom"
              size="small"
              value={userData?.user?.name || ''}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <InputLabel id="email-label">Email</InputLabel>
            <TextField
              fullWidth
              id="email"
              name="email"
              size="small"
              value={userData?.user?.email || ''}
              disabled
            />
          </Grid>
        </Grid>

        {/* Tableau des rôles */}
        <Typography variant="h6" sx={{ mt: 3 }}>
          Rôles de l'utilisateur
        </Typography>
        {isLoading ? (
          <Typography>Chargement...</Typography>
        ) : (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Rôle</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell align="center">Attribué</TableCell>
                  <TableCell align="center">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {userData?.roles?.map((role) => {
                  const hasRole = userData?.user?.roles?.some((r) => r.id === role.id);
                  return (
                    <TableRow key={role.id}>
                      <TableCell>{role.name}</TableCell>
                      <TableCell>{role.description}</TableCell>
                      <TableCell align="center">
                        <Checkbox checked={hasRole} disabled />
                      </TableCell>
                      <TableCell align="center">
                        <Button
                          variant="contained"
                          sx={{width:120}}
                          color={hasRole ? 'primary' : 'primary'}
                          onClick={() => handleRoleToggle(role)}
                        >
                          {hasRole ? 'Retirer' : 'Attribuer'}
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>
    </Container>
  );
};

export default RoleAdmin;
