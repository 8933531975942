import {
  Box,
  Stack,
  Typography,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  IconButton,
  Collapse,
  Tooltip,
} from "@mui/material";
import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import NotificationsBar from "../header/NotificationsBar";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";

import GroupsIcon from '@mui/icons-material/Groups';
import LayersIcon from "@mui/icons-material/Layers";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import MenuIcon from "@mui/icons-material/Menu";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty"; // Icone pour "en cours"
import DoneIcon from "@mui/icons-material/Done"; // Icone pour "terminées"
import FactCheckIcon from "@mui/icons-material/FactCheck"; //Icone pour validées
import EmailIcon from "@mui/icons-material/Email";
import ArticleIcon from "@mui/icons-material/Article";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import TableViewIcon from '@mui/icons-material/TableView';
import PostAddIcon from '@mui/icons-material/PostAdd';
import HistoryIcon from '@mui/icons-material/History';
import SyncLockSharpIcon from '@mui/icons-material/SyncLockSharp';
import SupervisorAccountTwoToneIcon from '@mui/icons-material/SupervisorAccountTwoTone';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

import { useAuth } from "../../contexts/AuthContext";
import Login from "./authentification/Login";

const drawerWidth = 240;
const navigationBarHeight = 64; // Hauteur de la barre de navigation principale

const MenuAdmin = () => {
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = React.useState(false); // État pour contrôler le mode réduit
  const [openReservations, setOpenReservations] = React.useState(false); // État pour gérer l'ouverture des sous-menus
  const [openDevises, setOpenDevises] = React.useState(false);
  const [openSecurite, setOpenSecurite] = React.useState(false);
  const { user } = useAuth();
  const handleNavigation = (path) => {
    navigate(path);
  };

  const handleCollapseToggle = () => {
    setCollapsed(!collapsed); // Bascule l'état du mode réduit
  };

  const handleReservationsClick = () => {
    setOpenReservations(!openReservations); // Ouvre/ferme les sous-menus
  };
  const handleDevisesClick = () => {
    setOpenDevises(!openDevises); // Ouvre/ferme les sous-menus
  };
  const handleSecuriteClick = () => {
    setOpenSecurite(!openSecurite); // Ouvre/ferme les sous-menus
  };

  // if (user?.user?.role!=='admin') {
  //   return null; // N'affiche rien si non authentifié
  // }
  return (
    <Stack
      direction="column"
      justifyContent={"flex-start"}
      alignItems={"center"}
      sx={{
        backgroundColor: "white",
        width: "100vw",
        padding: 0,
      }}
    >
      {user?.user?.roles?.includes('admin') || user?.user?.roles?.includes('supadmin')
 ? (
        <Stack direction="row" sx={{ height: "100%", width: "100%" }}>
          <Drawer
            variant="persistent"
            anchor="left"
            open
            sx={{
              width: collapsed ? 60 : drawerWidth,
              flexShrink: 0,
              marginTop: `${navigationBarHeight}px`, // Ajout d'une marge supérieure pour éviter de cacher la barre de navigation
              "& .MuiDrawer-paper": {
                width: collapsed ? 60 : drawerWidth,
                boxSizing: "border-box",
                transition: "width 0.7s ease",
                marginTop: `${navigationBarHeight}px`,
              },
            }}
          >
            {/* Bouton pour basculer entre le mode réduit et développé */}
            <IconButton onClick={handleCollapseToggle} sx={{ margin: 1 }}>
              {collapsed ? <MenuIcon /> : <MenuOpenIcon />}
            </IconButton>

            <List
              sx={{
                "& .MuiListItem-root": {
                  cursor: "pointer",
                },
              }}
            >
              {/* Menu Réservations avec sous-menus */}
              <ListItem button onClick={handleReservationsClick}>
                <ListItemIcon>
                  <Tooltip title="Réservations">{<ShoppingCartIcon />}</Tooltip>
                </ListItemIcon>
                {!collapsed && (
                  <Typography variant="body1">Réservation</Typography>
                )}
                {!collapsed &&
                  (openReservations ? <ExpandLessIcon /> : <ExpandMoreIcon />)}
              </ListItem>
              <Collapse in={openReservations} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem
                    button
                    sx={{ pl: 4 }} // padding à gauche pour indiquer que c'est un sous-menu
                    onClick={() =>
                      handleNavigation("/admin/gestionreservation/encours")
                    }
                  >
                    <ListItemIcon>
                      <Tooltip title="Réservations en cours">
                        <HourglassEmptyIcon />
                      </Tooltip>
                    </ListItemIcon>
                    <Typography variant="body2">
                      Réservations en cours
                    </Typography>
                  </ListItem>
                  <ListItem
                    button
                    sx={{ pl: 4 }}
                    onClick={() =>
                      handleNavigation("/admin/gestionreservation/validee")
                    }
                  >
                    <ListItemIcon>
                      <Tooltip title="Réservations validées">
                        <FactCheckIcon />
                      </Tooltip>
                    </ListItemIcon>
                    <Typography variant="body2">
                      Réservations validées
                    </Typography>
                  </ListItem>
                  <ListItem
                    button
                    sx={{ pl: 4 }}
                    onClick={() =>
                      handleNavigation("/admin/gestionreservation/terminee")
                    }
                  >
                    <ListItemIcon>
                      <Tooltip title="Réservations terminée">
                        <DoneIcon />
                      </Tooltip>
                    </ListItemIcon>
                    <Typography variant="body2">
                      Réservations terminée
                    </Typography>
                  </ListItem>
                </List>
              </Collapse>
              {/* Menu Devises avec sous-menus */}
              <ListItem button onClick={handleDevisesClick}>
                <ListItemIcon>
                  <Tooltip title="Devises">{<CurrencyExchangeIcon />}</Tooltip>
                </ListItemIcon>
                {!collapsed && <Typography variant="body1">Devises</Typography>}
                {!collapsed &&
                  (openDevises ? <ExpandLessIcon /> : <ExpandMoreIcon />)}
              </ListItem>
              <Collapse in={openDevises} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                <ListItem
                    button
                    sx={{ pl: 4 }}
                    onClick={() => handleNavigation("/admin/tableaucours")}
                  >
                    <ListItemIcon>
                      <Tooltip title="Tableau de cours">
                        {<TableViewIcon />}
                      </Tooltip>
                    </ListItemIcon>
                    {!collapsed && (
                      <Typography variant="body1">Tableau de cours</Typography>
                    )}
                  </ListItem>

                  <ListItem
                    button
                    sx={{ pl: 4 }}
                    onClick={() => handleNavigation("/admin/historique")}
                  >
                    <ListItemIcon>
                      <Tooltip title="Historique">
                        {<HistoryIcon />}
                      </Tooltip>
                    </ListItemIcon>
                    {!collapsed && (
                      <Typography variant="body1">Historique</Typography>
                    )}
                  </ListItem>
                  
                  <ListItem
                    button
                    sx={{ pl: 4 }}
                    onClick={() => handleNavigation("/admin/deviselistedit")}
                  >
                    <ListItemIcon>
                      <Tooltip title="Modification de devises">
                        {<PostAddIcon />}
                      </Tooltip>
                    </ListItemIcon>
                    {!collapsed && (
                      <Typography variant="body1">Modification</Typography>
                    )}
                  </ListItem>
                </List>
              </Collapse>

              <ListItem
                button
                onClick={() => handleNavigation("/admin/faqsadmin")}
              >
                <ListItemIcon>
                  <Tooltip title="FAQs">{<LiveHelpIcon />}</Tooltip>
                </ListItemIcon>
                {!collapsed && <Typography variant="body1">FAQs</Typography>}
              </ListItem>
              <ListItem
                button
                onClick={() => handleNavigation("/admin/newsadmin")}
              >
                <ListItemIcon>
                  <Tooltip title="News">{<ArticleIcon />}</Tooltip>
                </ListItemIcon>
                {!collapsed && <Typography variant="body1">News</Typography>}
              </ListItem>
              <ListItem
                button
                onClick={() => handleNavigation("/admin/contact")}
              >
                <ListItemIcon>
                  <Tooltip title="Messages">{<EmailIcon />}</Tooltip>
                </ListItemIcon>
                {!collapsed && (
                  <Typography variant="body1">Messages</Typography>
                )}
              </ListItem>
              <ListItem button onClick={handleSecuriteClick}>
                <ListItemIcon>
                  <Tooltip title="Sécurité">{<SyncLockSharpIcon />}</Tooltip>
                </ListItemIcon>
                {!collapsed && <Typography variant="body1">Sécurité</Typography>}
                {!collapsed &&
                  (openSecurite ? <ExpandLessIcon /> : <ExpandMoreIcon />)}
              </ListItem>
              <Collapse in={openSecurite} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                <ListItem
                    button
                    sx={{ pl: 4 }}
                    onClick={() => handleNavigation("/admin/role")}
                  >
                    <ListItemIcon>
                      <Tooltip title="Autorisations">
                        {<AdminPanelSettingsIcon />}
                      </Tooltip>
                    </ListItemIcon>
                    {!collapsed && (
                      <Typography variant="body1">Autorisations</Typography>
                    )}
                  </ListItem>   
                  <ListItem
                    button
                    sx={{ pl: 4 }}
                    onClick={() => handleNavigation("/admin/listadmins")}
                  >
                    <ListItemIcon>
                      <Tooltip title="Rôle Admin">
                        {<GroupsIcon />}
                      </Tooltip>
                    </ListItemIcon>
                    {!collapsed && (
                      <Typography variant="body1">Liste Admins</Typography>
                    )}
                  </ListItem>
                  <ListItem
                    button
                    sx={{ pl: 4 }}
                    onClick={() => handleNavigation("/admin/listsupadmins")}
                  >
                    <ListItemIcon>
                      <Tooltip title="Rôle Admin">
                        {<GroupsIcon />}
                      </Tooltip>
                    </ListItemIcon>
                    {!collapsed && (
                      <Typography variant="body1">Liste Sup Admins</Typography>
                    )}
                  </ListItem>
                  <ListItem
                    button
                    sx={{ pl: 4 }}
                    onClick={() => handleNavigation("/admin/listclient")}
                  >
                    <ListItemIcon>
                      <Tooltip title="Rôle Admin">
                        {<GroupsIcon />}
                      </Tooltip>
                    </ListItemIcon>
                    {!collapsed && (
                      <Typography variant="body1">Liste Clients</Typography>
                    )}
                  </ListItem>    

                </List>
              </Collapse>           



            </List>
          </Drawer>

          {/* Deuxième colonne : Contenu principal */}
          <Box
            sx={{
              flexGrow: 1,
              padding: 2,
              overflow: "auto", // Permet le défilement si le contenu dépasse la hauteur de l'écran
              transition: "margin-left 0.7s ease",
            }}
          >
            <NotificationsBar />
            <Outlet />
          </Box>
        </Stack>
      ) : (
        <Login />
      )}
    </Stack>
  );
};

export default MenuAdmin;
